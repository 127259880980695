/* src/components/Dashboard.css */

.dashboard-container {
  padding: 1rem;
}

.dashboard-header {
  background-color: #007bff;
  color: white;
  padding: 1rem;
}

.dashboard-content {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: -1px;
  /* Para sobrepor a borda superior do header */
}

.dashboard-content p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.count-square {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
}