.estoque-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.estoque-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.estoque-search select,
.estoque-search input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.estoque-search button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.estoque-search button:hover {
    background-color: #0056b3;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-ent {
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 500px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.modal-ent select,
.modal-ent input[type="text"],
.modal-ent input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-ent button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-ent button:hover {
    background-color: #0056b3;
}

.icon {
    margin-left: 10px;
    cursor: pointer;
    color: #007bff;
}

.icon:hover {
    color: #0056b3;
}