/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo do body para ocupar toda a tela */
body,
html,
#root {
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Container principal da aplicação */
.App {
  display: flex;
  height: 100%;
  flex-direction: column;
}

/* Estilo do cabeçalho (header) */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0px 0px;
  background: rgb(201, 213, 255);
  background: linear-gradient(333deg, rgb(0, 153, 255) 50%, rgb(72, 101, 249) 100%);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  height: 60px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.39);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100%;
}

.imagem-login {
  background: white;
  border-radius: 20px;
  height: 43px;
  font-size: xx-large;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 43px;
  margin-right: 10px;
}

.box-login {
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 50px;
  border: 1px solid #026fe4;
  background: #026ad8;
  border-radius: 43px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 20px;
}

.logo img {
  width: 90px;
  height: 55px;
}

.user-info {
  color: white;
  font-size: 18px;
  font-weight: bold;
}


/* Estilo da barra lateral */
.sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 20px;
  height: 90vh;
  position: fixed;
  top: 60px;
  /* Ajuste para a barra lateral começar logo abaixo do header */
  left: 0;
  overflow-y: auto;
  z-index: 99;
}

/* Estilo do conteúdo principal */
.content {
  margin-left: 250px;
  padding: 10px;
  background-color: #e9ecef;
  width: calc(100% - 250px);
  height: 100%;
  /* Ajustar o conteúdo para não ficar sob o header */
  margin-top: 60px;
  /* Para deixar espaço para o header */
  overflow-y: auto;
}

/* Estilo para links de navegação */
.sidebar nav ul {
  list-style-type: none;
}

.sidebar nav ul li {
  margin: 15px 0;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #007bff;
}

.sidebar nav ul li a:hover {
  color: #0056b3;
}

/* Estilo para o título de teste */
.App h1 {
  text-align: center;
  margin-bottom: 20px;
}