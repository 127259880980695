/* OrdemServico.css */

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ajuste o valor do z-index */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 400px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1001;
    /* Ajuste o valor do z-index */
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.modal-content ul {
    list-style: none;
    padding: 0;
}

.modal-content ul li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.modal-content ul li:hover {
    background-color: #f0f0f0;
}