/*====================  Container ========================*/
.container {
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}


/*====================  Titulo ========================*/
.title {
    justify-content: center;
    align-content: center;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 5px;
    font-size: larger;
    border-bottom: solid 1px rgba(0, 0, 0, 0.241);
    margin-bottom: 20px;

}


/*====================  Form Input Button Checkbox ========================*/
.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 2px;
    box-sizing: border-box;
}

.col {
    margin: 5px;
    margin-bottom: 15px;
}

.col label {
    display: block;
    margin-bottom: 5px;
    font-size: small;
    font-weight: bold;
    margin-left: 5px;
}

.col input {
    width: 100%;
    height: 25px;
    padding: 8px;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.form button[type="submit"] {
    height: 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form button[type="submit"]:hover {
    background-color: #0056b3;
}


.button[type="button"] {
    height: 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button[type="button"]:hover {
    background-color: #0056b3;
}

.checkbox {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #007bff;
    background-color: white;
    cursor: pointer;
    position: relative;
    outline: none;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.checkbox input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #007bff;
}

.checkbox input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}


.select {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 0px 5px;
    font-size: 15px;
    color: #333;
    width: 150px;
    height: 26px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.25);
}

.select option {
    padding: 8px 12px;
}





/*================================= Tabela =================================*/

.tabela-title {
    margin-top: 5px;
    padding-left: 5px;
    border-bottom: 1px solid;
    margin-bottom: 10px;
}

.tabela {
    margin-bottom: 10px;
    margin-top: 5px;
    background: rgba(116, 169, 255, 0.208);
}


.tabela ul {
    list-style-type: none;
    padding: 5px;
    max-height: 450px;
    overflow-y: auto;
}

.tabela li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    padding-left: 30px;
    background-color: #ffffff;
    border-radius: 4px;
    margin-bottom: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabela li:hover {
    cursor: pointer;
    background-color: rgb(154, 226, 255);
}


.icon {
    cursor: pointer;
    color: #007bff;
}

.icon:hover {
    color: #0056b3;
}