.nova-secretaria-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cadastro-header {
    font-size: 24px;
    margin-bottom: 20px;
}

.nova-secretaria-form {
    display: flex;
    flex-direction: column;
}

.div_imput {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.div_imput input {
    height: 30px;
    width: 48%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.custom-checkbox input {
    margin-right: 10px;
}

.custom-checkbox label {
    font-size: 16px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}